import "./TaiKhoanFabysa.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    getttShop,
    getTaiKhoan,
    updateTaiKhoan,
    registerTaiKhoan,
} from "../redux/apiRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
const TaiKhoanFabysa = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.login.currentUser);
    const ttShop = useSelector((state) => state?.ttShop?.ttShop?.ttShop?.shop);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const allTaiKhoan2 = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const [allTaiKhoan, setallTaiKhoan] = useState(allTaiKhoan2);
    useEffect(() => {
        setallTaiKhoan(allTaiKhoan2);
    }, [allTaiKhoan2]);
    console.log("allTaiKhoan", allTaiKhoan);

    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv, idTaiKhoan } =
        useParams();
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    const [soTien, setsoTien] = useState(0);
    const [dateMax, setdateMax] = useState(0);
    const [dateMin, setdateMin] = useState(1);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    useEffect(() => {
        getTaiKhoan(idTaiKhoan, dateMax, dateMin, dispatch);
    }, [idTaiKhoan]);
    //  Viet QR
    // const nganHang = tenNganHang;
    const BANK_ID = "970422";
    const ACCOUNT_NO = "0931969456666";
    const TEMPLATE = "print";
    const AMOUNT = soTien;
    const DESCRIPTION =
        idTaiKhoan === user?._id
            ? `USER_${user?.username}`
            : `SHOP_${ttShop?.sdtShop}`;
    const ACCOUNT_NAME = "TRAN VAN HUNG";
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    console.log("DESCRIPTION", DESCRIPTION);
    console.log("idTaiKhoan === user?._id", idTaiKhoan === user?._id);
    // Viet QR
    const d = new Date();
    const gioPhut = `${d.getHours()}h${d.getMinutes()}`;
    const ngayThang = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
    const handleLuuTaiKhoan = () => {
        if (soTien > 0) {
            if (idTaiKhoan === user?._id) {
                const newTaiKhoan = {
                    GDVao: soTien,
                    GDRa: "",
                    noiDungCK: "Fabysa Tặng",
                    xacNhanChuyenTien: "Thành Công",
                    thongTinThem: {
                        tenChuTk: myDetail?.hoTen,
                        sdtChuTk: user?.username,
                    },
                    idChuTaiKhoan: user?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);

                setallTaiKhoan([
                    {
                        GDVao: soTien,
                        GDRa: "",
                        noiDungCK: "Fabysa Tặng",
                        xacNhanChuyenTien: "Thành Công",
                        thongTinThem: {
                            tenChuTk: myDetail?.hoTen,
                            sdtChuTk: user?.username,
                        },
                        idChuTaiKhoan: user?._id,
                    },
                    ...allTaiKhoan,
                ]);
                setsoTien(0);
                registerTaiKhoan(newTaiKhoan, dispatch);
            } else {
                const newTaiKhoan = {
                    GDVao: soTien,
                    GDRa: "",
                    noiDungCK: "",
                    xacNhanChuyenTien: "Chờ Xác Nhận",
                    thongTinThem: {
                        tenChuTk: ttShop?.TenShop,
                        sdtChuTk: ttShop?.sdtShop,
                    },
                    idChuTaiKhoan: ttShop?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);

                setallTaiKhoan([
                    {
                        GDVao: soTien,
                        GDRa: "",
                        noiDungCK: "Fabysa Tặng",
                        xacNhanChuyenTien: "Chờ Xác Nhận",
                        thongTinThem: {
                            tenChuTk: ttShop?.TenShop,
                            sdtChuTk: ttShop?.sdtShop,
                        },
                        idChuTaiKhoan: ttShop?._id,
                        createdAt: new Date().toISOString(),
                    },
                    ...allTaiKhoan,
                ]);
                setsoTien(0);
                registerTaiKhoan(newTaiKhoan, dispatch);
            }
        } else {
            alert("Nhập số tiền mua");
        }
    };
    return (
        <div className="view">
            <div className="mobile">
                <div className="TaiKhoanFabysa-ConTaiNer">
                    <div className="quayLai-tieuDe">
                        {idTaiKhoan === user?._id ? (
                            <a
                                href={`/ca-nhan/${tenVietTat}/${idShop}/a/${idCtv}/${tenCtv}/${sdtCtv}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>
                        ) : (
                            <a
                                href={`/${tenVietTat}/${idShop}/a/${idCtv}/${tenCtv}/${sdtCtv}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>
                        )}

                        <div className="donHang">Tài Khoản Fabysa</div>
                        <div className="taiKhoanShop">
                            {handleDinhDangSo(ttShop?.cash)}&#160;
                            <i
                                className="fab fa-empire"
                                style={{ color: "#ef9b0f" }}
                            ></i>
                        </div>
                    </div>
                    <div className="napTien-lenhMoi">
                        <div className="napTien">Thông Tin Tài Khoản</div>
                        <div>
                            Chủ Tài Khoản:
                            {allTaiKhoan[0]?.thongTinThem?.tenChuTk}
                        </div>
                        <div>
                            Số Điện Thoại:
                            {allTaiKhoan[0]?.thongTinThem?.sdtChuTk}
                        </div>
                        {idTaiKhoan === user?._id ? (
                            <div>
                                {handleDinhDangSo(myDetail?.cash)}
                                &#160;
                                <i
                                    className="fab fa-empire"
                                    style={{ color: "#ef9b0f" }}
                                ></i>
                            </div>
                        ) : (
                            <div>
                                {handleDinhDangSo(ttShop?.cash)}
                                &#160;
                                <i
                                    className="fab fa-empire"
                                    style={{ color: "#ef9b0f" }}
                                ></i>
                            </div>
                        )}
                    </div>
                    <div className="napTien-lenhMoi">
                        <div className="napTien">
                            Nhận Fabysa Gold &#160;
                            <i
                                className="fab fa-empire"
                                style={{ color: "#ef9b0f" }}
                            ></i>
                        </div>
                    </div>
                    <div className="napTien-lenhMoi">
                        <div className="napTien">
                            Chuyển fabysa Gold&#160;
                            <i
                                className="fab fa-empire"
                                style={{ color: "#ef9b0f" }}
                            ></i>
                        </div>
                    </div>
                    <div className="napTien-lenhMoi">
                        <div className="napTien">
                            Mua Fabysa Gold &#160;
                            <i
                                className="fab fa-empire"
                                style={{ color: "#ef9b0f" }}
                            ></i>
                        </div>
                        <div className="quyDoi">
                            Quy đổi: 1 vnđ = 1 fabysa Gold
                        </div>
                        <div className="nhapSoTienTieuDe">Nhập số tiền mua</div>
                        <input
                            className="nhapSoTien"
                            placeholder={VND.format(soTien)}
                        />
                        <div className="menhGiaTien">
                            <div
                                onClick={() => setsoTien(soTien + 1000)}
                                className="giaTriTien"
                            >
                                {VND.format(1000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 2000)}
                                className="giaTriTien"
                            >
                                {VND.format(2000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 3000)}
                                className="giaTriTien"
                            >
                                {VND.format(3000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 5000)}
                                className="giaTriTien"
                            >
                                {VND.format(5000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 10000)}
                                className="giaTriTien"
                            >
                                {VND.format(10000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 20000)}
                                className="giaTriTien"
                            >
                                {VND.format(20000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 50000)}
                                className="giaTriTien"
                            >
                                {VND.format(50000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 100000)}
                                className="giaTriTien"
                            >
                                {VND.format(100000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 200000)}
                                className="giaTriTien"
                            >
                                {VND.format(200000)}
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + 500000)}
                                className="giaTriTien"
                            >
                                {VND.format(500000)}
                            </div>
                        </div>
                        <div className="thanhToan">Thanh Toán</div>
                        <div className="noiDungCk-AnhQr">
                            <img className="AnhQr" src={qr} />
                            <div className="noiDungCk">
                                <div className="chiTiet">
                                    Số Tiền: {VND.format(soTien)}
                                </div>
                                <div className="chiTiet">
                                    Nội dung CK: {DESCRIPTION}
                                </div>
                                <div className="chiTiet">
                                    Chủ TK: TRAN VAN HUNG
                                </div>
                                <div className="chiTiet">
                                    STK ADMIN: 0931969456666
                                </div>
                                <div className="chiTiet">
                                    Ngân Hàng TMCP Quân Đội
                                </div>
                            </div>
                        </div>
                    </div>
                    {allTaiKhoan &&
                    allTaiKhoan?.find(
                        (item) => item?.xacNhanChuyenTien === "Chờ Xác Nhận"
                    ) ? (
                        <div
                            onClick={() => alert("Đang xác nhận giao dịch!")}
                            className="choXacNhan"
                        >
                            Chờ Xác Nhận
                        </div>
                    ) : (
                        <div
                            onClick={() => handleLuuTaiKhoan()}
                            className="daThanhToan"
                        >
                            Đã Chuyển Khoản
                        </div>
                    )}
                    <div className="lichSu-container">
                        <div className="tieuDe">Lịch Sử Giao Dịch</div>
                        <div className="tienVao-tienRa">
                            <div className="tienVao">
                                <div className="tieuDe2">GD Vào</div>

                                <div className="allGiaoDich">
                                    {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDVao && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {item?.createdAt.slice(
                                                                0,
                                                                10
                                                            )}
                                                            <br />
                                                            {item?.createdAt.slice(
                                                                11,
                                                                19
                                                            )}
                                                        </div>
                                                        <div className="soTien">
                                                            {VND.format(
                                                                item?.GDVao
                                                            )}
                                                        </div>
                                                        <div className="noiDung">
                                                            {item?.noiDungCK}
                                                        </div>
                                                        {item?.xacNhanChuyenTien ===
                                                        "Thành Công" ? (
                                                            <div className="thanhCong">
                                                                {
                                                                    item?.xacNhanChuyenTien
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="xacNhan">
                                                                {
                                                                    item?.xacNhanChuyenTien
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                            <div className="tienVao">
                                <div className="tieuDe2">GD Ra</div>
                                <div className="allGiaoDich">
                                    {idTaiKhoan === user?._id ? (
                                        <div className="chiTiet">
                                            <div className="thoiGian">
                                                {user?.createdAt.slice(0, 10)}
                                                <br />
                                                {user?.createdAt.slice(11, 19)}
                                            </div>
                                            <div className="soTien">
                                                {VND.format(0)}
                                            </div>
                                            <div className="noiDung">
                                                Phí Mở Tài Khoản
                                            </div>
                                            <div className="thanhCong">
                                                Thành Công
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="chiTiet">
                                            <div className="thoiGian">
                                                {ttShop?.createdAt.slice(0, 10)}
                                                <br />
                                                {ttShop?.createdAt.slice(
                                                    11,
                                                    19
                                                )}
                                            </div>
                                            <div className="soTien">
                                                {VND.format(0)}
                                            </div>
                                            <div className="noiDung">
                                                Phí Mở Tài Khoản
                                            </div>
                                            <div className="thanhCong">
                                                Thành Công
                                            </div>
                                        </div>
                                    )}
                                       {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDRa && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {item?.createdAt.slice(
                                                                0,
                                                                10
                                                            )}
                                                            <br />
                                                            {item?.createdAt.slice(
                                                                11,
                                                                19
                                                            )}
                                                        </div>
                                                        <div className="soTien">
                                                            {VND.format(
                                                                item?.GDRa
                                                            )}
                                                        </div>
                                                        <div className="noiDung">
                                                            {item?.noiDungCK}
                                                        </div>
                                                        {item?.xacNhanChuyenTien ===
                                                        "Thành Công" ? (
                                                            <div className="thanhCong">
                                                                {
                                                                    item?.xacNhanChuyenTien
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="xacNhan">
                                                                {
                                                                    item?.xacNhanChuyenTien
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pc">
                <div className="TaiKhoanFabysa-ConTaiNer">TaiKhoanFabysa</div>
            </div>
        </div>
    );
};
export default TaiKhoanFabysa;
