import "./ThuNghiem.scss";
import React, { useState } from "react";
const ThuNghiem = () => {
    const [data, setdata] = useState("0587773555");
    return (
        <div className="ThuNghiem-ConTaiNer">
            <input type="number" onChange={(e) => setdata(e.target.value)} />
            <div>------------</div>
            <div>------------</div>
            <div>------------</div>
            <a href={`tel:${data}`}>
                <button> {data}</button>
            </a>

            <div>------------</div>
            <div>------------</div>
            <div>------------</div>
            <button onClick={() => window.open(`tel:${data}`)}>{data}</button>
        </div>
    );
};
export default ThuNghiem;
